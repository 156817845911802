import React, { Component, Fragment, PureComponent } from 'react';

import styled, { css } from 'styled-components';

import Box from '../atoms/Box/Box';
import { Col, Row } from '../atoms/Grid';
import Text from '../atoms/Text/Text';

import { Align } from '@shared/enums/align';
import { breakpoint } from 'theme';
import moment from 'moment';

const sharedCss = css`
  font-size: 2.5em;

  ${breakpoint.m`
    font-size: 3em;
  `}
`;

const ColDivider = styled(Col)`
  ${sharedCss};
`;

const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ColItem = styled(Col)`
  text-align: center;
`;

const TextItem = styled(Text)`
  ${sharedCss};
`;

const TextItemType = styled(Text)`
  font-size: 1em;
`;

type HeaderProps = {
  discountTitle: string;
};

class Header extends PureComponent<HeaderProps> {
  render() {
    return (
      <Box>
        <Text
          component="div"
          smSize={0.875}
          size={1.375}
          align={Align.Center}
          lineHeight="normal"
        >
          {this.props.discountTitle}
        </Text>
      </Box>
    );
  }
}

type ItemProps = {
  value: number;
  type: string;
  hasDivider: boolean;
};

class Item extends PureComponent<ItemProps> {
  render() {
    return (
      <Fragment>
        <ColItem>
          <TextItem>{this.props.value}</TextItem>
          <br />
          <TextItemType>{this.props.type}</TextItemType>
        </ColItem>
        {this.props.hasDivider && <ColDivider>:</ColDivider>}
      </Fragment>
    );
  }
}

type SaleTimerProps = {
  className?: string;
  discountTitle?: string;
  showCountDown?: boolean;
  finishDate?: string | Date;
};

class SaleTimer extends Component<SaleTimerProps> {
  private timer: number | NodeJS.Timer;

  state = {
    time: [],
  };

  startTimer = () => {
    this.timer = setInterval(this.countDown, 1000);
  };

  countDown = () => {
    const difference = moment(this.props.finishDate).diff(moment());
    const differenceObject = moment.duration(difference);
    const days = differenceObject.days();
    const hours = differenceObject.hours();
    const minutes = differenceObject.minutes();
    const seconds = differenceObject.seconds();

    this.setState({
      time: [
        {
          value: days,
          type: days === 1 ? 'day' : 'days',
          hasDivider: true,
        },
        {
          value: hours,
          type: hours === 1 ? 'hr' : 'hrs',
          hasDivider: true,
        },
        {
          value: minutes,
          type: minutes === 1 ? 'min' : 'mins',
          hasDivider: true,
        },
        {
          value: seconds,
          type: seconds === 1 ? 'sec' : 'secs',
          hasDivider: false,
        },
      ],
    });

    if (this.timer && difference <= 0) {
      clearInterval(this.timer);
    }
  };

  componentDidMount() {
    if (this.props.showCountDown) {
      this.startTimer();
    }
  }

  componentWillUnmount() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  }

  render() {
    return (
      <div className={this.props.className}>
        <InnerWrapper>
          <Header discountTitle={this.props.discountTitle} />
          {this.state.time.length > 0 && (
            <Box mt={0.375}>
              <Row>
                {this.state.time.map((item, i) => {
                  return (
                    <Item
                      key={i}
                      value={item.value}
                      type={item.type}
                      hasDivider={item.hasDivider}
                    />
                  );
                })}
              </Row>
            </Box>
          )}
        </InnerWrapper>
      </div>
    );
  }
}

const styledSaleTimer = styled(SaleTimer)`
  background-color: ${props => props.theme.colors.mulberry};
  padding: 1em 0.5em 1em;
  color: ${props => props.theme.colors.white};
  font-weight: 600;
`;

export default styledSaleTimer;
